import { useField } from 'formik';
import React from 'react';
import omit from 'lodash/omit';
import cn from 'classnames';

import { ERRORS } from '@api/routes/public/submissions/generate/params';
import { texts } from 'src/constants/texts';

import css from './UploadInput.module.scss';

type Props = {
  className?: string;
  name: string;
  disabled?: boolean;
};

const UploadInput: React.FC<Props> = ({ name, disabled = false, className = '' }) => {
  const [field, meta, helpers] = useField(name);
  const shouldRenderError = Boolean(meta.touched) && Boolean(meta.error);
  const errorMessage = meta.error === ERRORS.FILE_SIZE_LIMIT ? texts.general.errors.fileInput.size : meta.error;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setTouched(true);
    const file = e.currentTarget.files?.[0];

    if (file) helpers.setValue(file);
  };

  return (
    <>
      <div className={cn(css.uploadInput, className, disabled && css.disabled)}>
        <label>
          <input type="file" {...omit(field, 'value')} onChange={onChange} accept="image/jpeg, image/png" />

          <div className={css.addBtn}>
            <span>+</span>
          </div>
        </label>

        <div className={css.info}>
          <div className={css.notes}>{texts.generator.inputs.image.label}</div>
          <div className={css.acceptFiles}>{`(${texts.generator.inputs.image.info})`}</div>
          {shouldRenderError && <div className={css.error}>{errorMessage}</div>}
        </div>
        <div className={css.footNote}>{texts.generator.inputs.image.footNote}</div>
      </div>
    </>
  );
};

export default UploadInput;
